<template>
	<div>
		<div class="panel">
			<div class="panel-body pad16">
				<div class="panel-body" style="border-bottom: 1px solid #EBEEF5;">
					<div class="fcj_lr" style="border-bottom:1px solid #e4e7ed;padding-bottom: 20px;margin-bottom:20px;">
						<div class="fcj_ll">
							<div style="margin-right:5px;"><el-button :type="active == 'meterlist' ? 'primary' : 'info'"
									plain @click="chac('meterlist')" size="small">用量统计</el-button></div>
							<div style="margin-right:5px;"><el-button :type="active == 'elemeter' ? 'primary' : 'info'"
									plain @click="chac('elemeter')" size="small">水/电/气表</el-button></div>
							<div style="margin-right:5px;"><el-button :type="active == 'config' ? 'primary' : 'info'" plain
									@click="chac('config')" size="small">月度记录</el-button></div>
							<div style="width:30px;"></div>

							<div style="margin-right:5px;"><el-button :type="active == 'showbill' ? 'primary' : 'info'" plain
								@click="chac('showbill')" size="small">生成账单</el-button></div>
						</div>
						<div>
							<div style="margin-right:5px;"><el-button type="info" plain
								@click="showloadfile()" size="small">导入数据</el-button></div>
						</div>
					</div>



					<div v-show="active == 'meterlist'">
						<div class="fcj_lr table-filter">

							<div class="fcj_ll">
								<el-input placeholder="请输入公司名称 " size="small" v-model="searchkey.keywords"
									class="input-with-select" @keyup.enter.native="getdata" style="width:260px;">
								</el-input>
								<el-tooltip class="item" effect="dark" :content="searchproperty" placement="top-start">
									<property v-model="chproperty" :fsize="['small']" mul="1" @change="ch" style="margin:0 10px;">
									</property>
								</el-tooltip>
								<div>
									<!-- <div class="fcj_ll">
										<div style="font-size:13px;width:60px;">日期范围</div>
										<div>
											<el-date-picker v-model="searchkey.day" value-format="yyyy-MM-dd" size="small"
												type="daterange" range-separator="至" start-placeholder="开始日期"
												end-placeholder="结束日期" style="width:270px;">
											</el-date-picker>
										</div>

									</div> -->
									<el-date-picker v-model="searchkey.month" value-format="yyyy-MM" size="small"
										type="month" 
										placeholder="月份" style="width:150px;">
									</el-date-picker>
								</div>
								<el-select v-model="searchkey.use" slot="prepend" size="small" clearable placeholder="所属人"
									@change="getdata" class="searchitem" style="width:100px">
									<el-option :label="item" :value="index" v-for="(item,index) in belong" v-show="item!=''" :key="index"></el-option>
								</el-select>
								<el-select v-model="searchkey.tp" slot="prepend" size="small" clearable placeholder="表类型"
									@change="getdata" class="searchitem" style="width:100px">
									<el-option :label="item" :value="index" v-for="(item,index) in meterType" v-show="item!=''" :key="index"></el-option>
								</el-select>
								<el-input placeholder="表编号 " size="small" v-model="searchkey.number"
									style="width:100px;"></el-input>

							</div>




						</div>
						<div class="fcj_ll">
							
							<el-button size="small" type="primary" icon="el-icon-search" @click="getdata">查询</el-button>
							<el-button size="small" type="success" icon="el-icon-takeaway-box"
								@click="getdata('down')">导出</el-button>
							<el-button size="small" type="warning" icon="el-icon-edit-outline"
								@click="read">批量自动抄表</el-button>
							<div v-if="reading" style="margin-left:20px; width:200px;">
								<el-progress :percentage="percent"></el-progress>
							</div>
						</div>

						<div>
							<el-table :data="meters" @selection-change="handleSelectionChange"
								header-cell-class-name="table-header" stripe>
								<el-table-column type="selection" width="55" fixed>
								</el-table-column>
								<el-table-column prop="company" label="租户" width="200">
									<template slot-scope="scope">
										<router-link :to="'/editcustomer?proview=1&id=' + scope.row.customerid">{{
											scope.row.company }}</router-link>
									</template>
								</el-table-column>
								<el-table-column prop="number" label="表编号" width="80px">
								</el-table-column>
								<el-table-column prop="roomname" label="表名" >
								</el-table-column>
								<el-table-column prop="bname" label="楼栋楼层" width="100px">
									<template slot-scope="scope">
										<router-link :to="'/areaedit?bid=' + scope.row.building + '&fid=' + scope.row.floor">{{
												scope.row.bname + scope.row.fname }}</router-link>
									</template>
								</el-table-column>
								<el-table-column prop="roomnumber" label="室号" width="80">
									<template slot-scope="scope">
										<router-link
											:to="'/officeinfo?bid=' + scope.row.building + '&fid=' + scope.row.floor + '&id=' + scope.row.officeid">{{
												scope.row.roomnumber }}</router-link>
									</template>
								</el-table-column>
								<el-table-column prop="tp" label="表类型"  width="100">
									<template slot-scope="scope">
										{{ scope.row.tp ?meterType[scope.row.tp]:'' }}
									</template>
								</el-table-column>
								
								<el-table-column prop="watt" label="用量" width="100">
								</el-table-column>

								<el-table-column prop="use" label="用途" width="50">
									<template slot-scope="scope">
										{{ scope.row.use>0?belong[scope.row.use]:'' }}
									</template>
								</el-table-column>

								<el-table-column prop="statename" label="合同状态" width="80">
								</el-table-column>


								
								<el-table-column label="合同租期" width="180">
									<template slot-scope="scope">
										{{ scope.row.starttime + '~' + scope.row.endtime }}
									</template>
								</el-table-column>
								
								
								

								<template>
									<div slot="empty" style="padding:50px 0;"> <img :src="host + 'images/empty.png'"
											class="emptyimg" alt="">
										<div>暂无数据</div>
									</div>
								</template>
							</el-table>
							<div style="padding:15px 0;text-align: right;">
								<div class="fcj_lr">
									<div style="font-size:12px;color:#999;"></div>
									<div>
										<el-pagination background layout="total,prev, pager, next" :current-page.sync="pgno"
											:page-size="pgsize" @current-change="getdata" :total="pgcount">
										</el-pagination>

									</div>
								</div>

							</div>
						</div>
					</div>

					<div v-show="active == 'elemeter'">
						<div class="fcj_ll">
							<el-input placeholder="请输入公司名称 " size="small" v-model="metersearchkey.company"
									class="input-with-select" style="width:260px;">
								</el-input>
							<el-input placeholder="室号 " size="small" v-model="metersearchkey.offciname"
								class="input-with-select" style="width:160px;margin:0 5px;">
							</el-input>
							<el-input placeholder="表名 " size="small" v-model="metersearchkey.roomname"
								class="input-with-select" style="width:160px;margin:0 5px;">
							</el-input>
							<el-input placeholder="表id " size="small" v-model="metersearchkey.id"
								class="input-with-select" style="width:120px;margin:0 5px;">
							</el-input>
							<el-input placeholder="电表一维码 " size="small" v-model="metersearchkey.mac"
								class="input-with-select" style="width:160px;margin:0 5px;">
								
							</el-input>
							<el-button type="primary" slot="append" size="small" style="margin:0 5px;" icon="el-icon-search" @click="getmeter">查询</el-button>
							<el-button type="success" size="small" icon="el-icon-plus" style="margin-left:5px;"
								@click="showaddmeter">添加电表</el-button>
						</div>
						<div>
							<el-table :data="elemeter" header-cell-class-name="table-header" stripe>
								<el-table-column prop="id" label="表id" width="50px">
								</el-table-column>
								<el-table-column prop="tp" label="表类型" width="70px">
									<template slot-scope="scope">
										<el-tag :type="typebutton[scope.row.tp-1]">{{ meterType[scope.row.tp]}}</el-tag>
									</template>
								</el-table-column>
								<el-table-column prop="roomname" label="表名" width="150px">
									<template slot-scope="scope">
										<div @click="showlog(scope.row)" style="color:#409EFF;cursor:pointer;">{{ scope.row.roomname}}</div>
									</template>
								</el-table-column>
								<el-table-column prop="bname" label="绑定房间" width="150px">
									<template slot-scope="scope">
										<div v-if="scope.row.officeid>0">{{ scope.row.bname + scope.row.roomnumber }}</div>
										<div v-else style="color:#ccc;">未绑房间</div>
									</template>
								</el-table-column>
								<el-table-column prop="company" label="绑定合同" >
									<template slot-scope="scope">
										<div v-if="scope.row.contractid>0">{{ scope.row.company}}</div>
										<div v-else style="color:#ccc;">未绑合同</div>
									</template>
								</el-table-column>
								<el-table-column prop="number" label="编号" width="50px">
								</el-table-column>
								<el-table-column prop="mac" label="电表编码" width="160px">
								</el-table-column>
								<el-table-column prop="magnification" label="倍率" width="50px">
								</el-table-column>

								<el-table-column prop="note" label="备注">
								</el-table-column>
								<el-table-column prop="maddr" label="接口编码" width="140px">
								</el-table-column>
								<el-table-column prop="note" label="操作" width="170px">
									<template slot-scope="scope">
										<el-button @click="modimeter(scope.row)" plain type="primary" size="small"
											style="margin-right:5px;" icon="el-icon-edit">修改</el-button>
										<el-popconfirm title="确定删除吗？" @confirm="delmeter(scope.row)"><el-button
												type="danger" slot="reference" plain size="small"
												icon="el-icon-delete">删除</el-button></el-popconfirm>

									</template>
								</el-table-column>
							</el-table>
							<div style="padding:15px 0;text-align: right;">
								<div class="fcj_lr">
									<div style="font-size:12px;color:#999;"></div>
									<div>
										<el-pagination background layout="total,prev, pager, next"
											:current-page.sync="elemeter_pgno" :page-size="elemeter_pgsize"
											@current-change="getmeter" :total="elemeter_pgcount">
										</el-pagination>

									</div>
								</div>

							</div>
						</div>

					</div>

					<div v-show="active == 'config'">
						<div class="fcj_lr table-filter">

							<div class="fcj_ll">
								<el-button type="primary" size="small" icon="el-icon-plus"
									@click="newconfig">添加月度数据</el-button>

							</div>
						</div>
						<div>
							<el-table :data="config" header-cell-class-name="table-header" stripe>
								<el-table-column prop="month" label="月份" width="100px">
								</el-table-column>
								<el-table-column prop="price" label="商户单价" width="150px">
									<template slot-scope="scope">
										¥ {{ scope.row.price }}
									</template>
								</el-table-column>
								<el-table-column prop="allmoney" label="电网总费用" width="180">
									<template slot-scope="scope">
										{{ scope.row.allmoney }}
									</template>
								</el-table-column>
								<el-table-column prop="allwat" label="电网总电量" width="150">
									<template slot-scope="scope">
										{{ scope.row.allwat }}
									</template>
								</el-table-column>

								<el-table-column label="添加人">
									<template slot-scope="scope">
										{{ scope.row.usrn }}
									</template>
								</el-table-column>

								<el-table-column prop="builduparea" label="操作" width="180">
									<template slot-scope="scope">
										<el-button @click="modiconfig(scope.row)" plain type="primary">修改</el-button>
										<el-button @click="delconfig(scope.row)" plain type="danger">删除</el-button>

									</template>
								</el-table-column>


								<template>
									<div slot="empty" style="padding:50px 0;"> <img :src="host + 'images/empty.png'"
											class="emptyimg" alt="">
										<div>暂无数据</div>
									</div>
								</template>
							</el-table>
							<div style="padding:15px 0;text-align: right;">
								<div class="fcj_lr">
									<div style="font-size:12px;color:#999;"></div>
									<div>
										<el-pagination background layout="total,prev, pager, next"
											:current-page.sync="month_pgno" :page-size="month_pgsize"
											@current-change="getdata" :total="month_pgcount">
										</el-pagination>

									</div>
								</div>

							</div>
						</div>
					</div>

					<div v-show="active == 'showbill'">
						<div class="fcj_lr table-filter">

							<div class="fcj_ll">
								<div>
									<el-date-picker v-model="searchbill.month" value-format="yyyy-MM" size="small"
										type="month" 
										placeholder="月份" style="width:150px;">
									</el-date-picker>
								</div>
								<el-input placeholder="请输入公司名称 " size="small" v-model="searchbill.company"
									class="input-with-select" @keyup.enter.native="getdata" style="width:260px;">
								</el-input>
								
								

								<el-select v-model="searchbill.use" slot="prepend" size="small" clearable placeholder="所属人"
									@change="getdata" class="searchitem" style="width:100px">
									<el-option :label="item" :value="index" v-for="(item,index) in belong" v-show="item!=''" :key="index"></el-option>
								</el-select>
								<el-select v-model="searchbill.tp" slot="prepend" size="small" clearable placeholder="表类型"
									@change="getdata" class="searchitem" style="width:100px">
									<el-option :label="item" :value="index" v-for="(item,index) in meterType" v-show="item!=''" :key="index"></el-option>
								</el-select>
								
								<el-button size="small" type="primary" icon="el-icon-search" @click="viewBill">预览账单</el-button>
								<el-button size="small" type="primary" icon="el-icon-search" @click="showviewBill('save')">生成账单</el-button>
								<el-button size="small" type="success" icon="el-icon-takeaway-box"
								@click="viewBill('down')">导出</el-button>
								
							</div>
						</div>
						

						<div>
							<el-table :data="bill" @selection-change="handleSelectionChange"
								header-cell-class-name="table-header" stripe>
								<el-table-column type="selection" width="55" fixed>
								</el-table-column>
								<el-table-column prop="number" label="合同编号" width="80px">
								</el-table-column>
								<el-table-column prop="company" label="租户" width="200">
									<template slot-scope="scope">
										<router-link :to="'/editcustomer?proview=1&id=' + scope.row.customerid">{{
											scope.row.company }}</router-link>
									</template>
								</el-table-column>
								<el-table-column prop="officename" label="房间名" >
								</el-table-column>
								<el-table-column prop="type" label="表类型"  width="100">
								</el-table-column>
								<el-table-column prop="month" label="月份"  width="100">
								</el-table-column>
								<el-table-column prop="predigit" label="上月抄表数" width="100">
								</el-table-column>
								<el-table-column prop="thidigit" label="本月抄表数" width="100">
								</el-table-column>
								<el-table-column prop="days" label="天数" width="100">
								</el-table-column>
								<el-table-column prop="watt" label="消耗" width="100">
								</el-table-column>

								<el-table-column prop="price" label="单价" width="100">
								</el-table-column>
								<el-table-column prop="magnification" label="倍率" width="80">
								</el-table-column>
								<el-table-column prop="amount" label="金额" width="150">
								</el-table-column>
								<template>
									<div slot="empty" style="padding:50px 0;"> <img :src="host + 'images/empty.png'"
											class="emptyimg" alt="">
										<div>暂无数据</div>
									</div>
								</template>
							</el-table>
							
						</div>
					</div>

				</div>
			</div>
		</div>

		<!-- 添加月度记录 -->
		<el-dialog :visible.sync="showaddconfig" width="500px" :close-on-click-modal="false">
			<div style="margin-bottom:20px;">
				<el-form :model="monthconfig" :rules="monthconfig_rule" ref="monthconfig" label-width="160px">
					<el-row>
						<el-col :span="12">
							<el-form-item label="月份" prop="date">
								<el-date-picker type="month" placeholder="选择日期" v-model="monthconfig.month"
									style="width: 180px;">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="用户单价" prop="price">
								<el-input style="width:180px;" v-model="monthconfig.price"><template
										slot="append">元</template></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="电局总价" prop="allmoney">
								<el-input style="width:180px;" v-model="monthconfig.allmoney"><template
										slot="append">元</template></el-input>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="电局总度数" prop="allwat">
								<el-input style="width:180px;" v-model="monthconfig.allwat"><template
										slot="append">度</template></el-input>
							</el-form-item>
						</el-col>

					</el-row>
					<el-form-item>
						<el-button type="primary" @click="subconfig">确认</el-button>
						<!-- <el-button type="warning" @click="ddrerent()">发起钉钉审批</el-button> -->

						<el-button @click="cancelconfigform">关闭</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>

		<!-- 修改添加电表 -->
		<el-dialog :visible.sync="isshowaddmeter" width="800px" :close-on-click-modal="false">
			<div style="margin-bottom:20px;">
				<el-form :model="meterform" :rules="meterform_rule" ref="meterform" label-width="160px">
					<el-form-item label="表类型" prop="tp">
						<el-radio-group v-model="meterform.tp" @change="tpchange">
							<el-radio name="tp" :label="index" :value="index" v-for="(item,index) in meterType" v-show="item!=''" :key="index+'c'">{{ item }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="表二级分类" prop="stype" v-if="meterform.tp<3">
						<el-radio-group v-model="meterform.stype">
							<el-radio name="stype" :label="index" :value="index" v-for="(item,index) in meterSecondType[meterform.tp-1]" v-show="item!=''" :key="index+'a'">{{ item }}</el-radio>
						</el-radio-group>
					</el-form-item>
					
					<el-form-item label="所有人" prop="belong">
						<el-radio-group v-model="meterform.belong">
							<el-radio name="belong" :label="index" :value="index" v-for="(item,index) in belong" v-show="item!=''" :key="index+'b'">{{ item }}</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="关联物业" prop="officeid">
						<div class="fcj_ll">
							<div>
								<property v-model="linkproperty" :mul="'1'" :fsize="['small']" @change="chs" />
							</div>
							<div>
								<div style="margin-left: 10px;">{{ linkpropertyname }}</div>
							</div>
							<div v-if="linkpropertyname"><el-button type="warning" plain size="mini"
									style="margin-left:10px;" @click="clearofficeid">清除</el-button></div>
						</div>
					</el-form-item>
					
					
					<el-form-item label="关联合同" prop="company">
						<el-autocomplete
						v-model="comkey"
						:fetch-suggestions="searchcom"
						placeholder="请输入内容"
						@select="handleSelect"
						style="width:350px"
						>
							<template slot-scope="{ item }">
								<div class="fcj_ll">
									<div style="margin-right:20px;">{{ item.number+','+item.company }}</div>
								</div>
							
							</template>
						</el-autocomplete>
						<el-button type="warning" plain  v-if="meterform.contractid>0" 
						style="margin-left:10px;" @click="clearcontract">清除</el-button>
					</el-form-item>



					<el-form-item label="表名" prop="roomname">
						<el-input style="width:240px;" v-model="meterform.roomname"></el-input>
					</el-form-item>


					<el-form-item label="编码" prop="number">
						<el-input-number controls-position="right" :step="1" :min="1" style="width:140px;"
							v-model="meterform.number"></el-input-number>
					</el-form-item>


					<el-form-item label="倍率" prop="magnification">
						<el-input-number controls-position="right" :step="1" :min="1" style="width:140px;"
							v-model="meterform.magnification"></el-input-number>
					</el-form-item>


					<el-form-item label="接口编号" prop="maddr">
						<el-input style="width:200px;" v-model="meterform.maddr"></el-input>
					</el-form-item>


					<el-form-item label="表唯一编码" prop="mac">
						<el-input style="width:200px;" v-model="meterform.mac"></el-input>
					</el-form-item>
					<el-form-item label="是否为实体表" prop="virtual">
						<el-radio-group v-model="meterform.virtual">
							<el-radio name="virtual" label="1" :value="1" >实体表</el-radio>
							<el-radio name="virtual" label="0" :value="0" >虚拟表</el-radio>
						</el-radio-group>
					</el-form-item>


					<el-form-item label="备注" prop="note">
						<el-input v-model="meterform.note" type="textarea" maxlength="200" :rows="5"
							show-word-limit></el-input>
					</el-form-item>


					<el-form-item>
						<el-button type="primary" @click="submeter">确认</el-button>
						<!-- <el-button type="warning" @click="ddrerent()">发起钉钉审批</el-button> -->

						<el-button @click="closemeterform">关闭</el-button>
					</el-form-item>
				</el-form>
			</div>
		</el-dialog>

		<!-- 抄表记录 -->
		<el-dialog :visible.sync="showMeterLog" width="900px" :close-on-click-modal="false" top="10px">
			<div style="margin-bottom:20px;height: 700px;">
				<el-tabs v-model="activeName" type="card" @tab-click="logchange">
					<el-tab-pane label="抄表记录" name="first">
						<el-table :data="meterlog" header-cell-class-name="table-header" stripe>
							<el-table-column prop="hour" label="抄表时间" width="120px">
							</el-table-column>
							<el-table-column prop="event_name" label="事件名称" width="150px">
								<template slot-scope="scope">
									
									<div v-if="scope.row.event_name=='record'">{{ event_name[scope.row.event_name]}}</div>
									<el-tag v-else :type="scope.row.event_name=='regist'?'success':'warning'">{{ event_name[scope.row.event_name]}}</el-tag>
								</template>
							</el-table-column>
							<el-table-column prop="watt" label="读数" width="150px">
							</el-table-column>
							<el-table-column prop="magnification" label="倍率" width="50px">
							</el-table-column>
							<el-table-column prop="state" label="是否已生成账单" width="120px">
							</el-table-column>
							<el-table-column prop="meter_name" label="表名" >
							</el-table-column>

							
						</el-table>
						<div style="padding:15px 0;text-align: right;">
							<div class="fcj_lr">
								<div style="font-size:12px;color:#999;"></div>
								<div>
									<el-pagination background layout="total,prev, pager, next"
										:current-page.sync="meterLog_pgno" :page-size="meterLog_pgsize"
										@current-change="showlog" :total="meterLog_pgcount">
									</el-pagination>

								</div>
							</div>

						</div>
					</el-tab-pane>
					<el-tab-pane label="操作记录" name="second">
						<el-table :data="meterchangelog" header-cell-class-name="table-header" stripe width="100%">
							<el-table-column prop="create_time" label="操作时间" width="200px">
							</el-table-column>
							<el-table-column prop="name" label="操作人" width="150px">
							</el-table-column>
							<el-table-column prop="note" label="操作内容" width="500px">
							</el-table-column>
						</el-table>
						<div style="padding:15px 0;text-align: right;">
							<div class="fcj_lr">
								<div style="font-size:12px;color:#999;"></div>
								<div>
									<el-pagination background layout="total,prev, pager, next"
										:current-page.sync="meterChangeLog_pgno" :page-size="meterLog_pgsize"
										@current-change="showMeterChangeLog" :total="meterChangeLog_pgcount">
									</el-pagination>

								</div>
							</div>

						</div>
					</el-tab-pane>
				</el-tabs>
			</div>
		</el-dialog>

		<!-- 重名合同 -->
		<el-dialog :visible.sync="chcompany" width="900px" :close-on-click-modal="false">
			<div style="margin-bottom:20px;">
				<el-table :data="companyList" header-cell-class-name="table-header" stripe>
					<el-table-column prop="number" label="合同编号" width="120px">
					</el-table-column>
					<el-table-column prop="company" label="合同名称" >
						
					</el-table-column>
					<el-table-column prop="regist_time" label="开始时间" width="150px">
					</el-table-column>
					<el-table-column prop="term" label="结束时间" width="150px">
					</el-table-column>
					

					<el-table-column prop="note" label="操作" width="100px">
						<template slot-scope="scope">
							<el-button @click="surecompany(scope.row.id)" plain type="primary" size="small"
								style="margin-right:5px;" icon="el-icon-edit">选择</el-button>
						</template>
					</el-table-column>
				</el-table>
				
			</div>
		</el-dialog>


		<!-- 导入数据 -->
		<el-dialog :visible.sync="isshowload" width="500px" :close-on-click-modal="false">
			<div style="margin-bottom:20px;">
				<el-form :model="daoru" :rules="daoru_rule" ref="daoru" label-width="160px">
					<el-row>
						<el-col :span="12">
							<el-form-item label="日期" prop="day">
								<el-date-picker type="date" placeholder="选择日期" v-model="daoru.day"
									style="width: 200px;">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					<el-row>
						<el-col :span="12">
							<el-form-item label="文件类型" prop="tp">
								<el-select placeholder="请选择" v-model="daoru.tp" style="width: 200px;">
									<el-option label="上传空调计费" value="2"></el-option>
									<el-option label="上传水，气费合并账单" value="1"></el-option>
								</el-select>
							</el-form-item>
						</el-col>
					</el-row>


					<el-form-item label="数据文件" prop="files" style="min-height: 50px;">
						<el-upload :action="host+'upfile/'" :show-file-list="true" list-type="text" ref="uploadfloorpic1" name="upfile"
							:on-success="uploadfloorpicsuccess" :on-change="filechange" :multiple="false" :limit="1" :file-list="fileList" :auto-upload="false" :data="uploaddata">
						
							<el-button type="primary" size="small" icon="el-icon-upload2">上传文件
							</el-button>
						
						</el-upload>
					</el-form-item>
				
					<el-form-item>
						<el-button type="primary" @click="subdaoru()" :loading="upefile" icon="el-icon-document" style="margin-top:50px;">保存</el-button>
					</el-form-item>

					

				</el-form>
			</div>
		</el-dialog>


		<!-- 生成账单设置发票 -->
		<el-dialog :visible.sync="isshowinvoice" width="500px" :close-on-click-modal="false">
			<div style="margin-bottom:20px;">
				<el-form :model="invoice" :rules="invoice_rule" ref="invoice" label-width="160px">
					<el-row>
						<el-col :span="12">
							<el-form-item label="发票日期" prop="invoice_in_time">
								<el-date-picker type="date" placeholder="选择日期" v-model="invoice.invoice_in_time"
									style="width: 200px;">
								</el-date-picker>
							</el-form-item>
						</el-col>
					</el-row>
					
				
					<el-form-item>
						<el-button type="primary" @click="subinvoice()" :loading="upefile" icon="el-icon-document" style="margin-top:50px;">保存</el-button>
					</el-form-item>

					

				</el-form>
			</div>
		</el-dialog>


	</div>
</template>


<script>
function name(params) {

}
import property from '../components/property.vue'
export default {
	components: {
		property
	},
	data() {
		return {
			isshowinvoice:false,
			uploadForm:{
					files:[]
				},
			upefile:false,
			uploaddata:{
					pusern:'',
					backapi:1,
					sys:localStorage.getItem('sys')
				},
			typebutton:['primary','success','warning'],
			activeName: 'first',
			searchbill:{
				'company':'',
				'contractid':0,
				'month':'',
				'use':'',
				'tp':''
			},
			bill:[],
			chcompany:false,
			companyList:[],
			meterLog_pgno:1,
			meterLog_pgsize:20,
			meterLog_pgcount:1,
			host: '',
			active: 'meterlist',
			showMeterLog:false,
			comkey:'',
			linkproperty: {},
			linkpropertyname: '',
			isshowaddmeter: false,
			meterform: {
				'tp':1,
				'stype':'',
				'officeid': '',
				'roomname': '',
				'number': '',
				'magnification': '',
				'maddr': '',
				'mac': '',
				'note': '',
				'id': '',
				'belong':'',
				'virtual':'1'
			},
			meterform_rule: {
				tp: [{
					required: true,
					message: '请选择表类型',
					trigger: 'change'
				}],
				stype: [{
					required: true,
					message: '请选择表二级分类',
					trigger: 'change'
				}],
				belong: [{
					required: true,
					message: '请选择所有人',
					trigger: 'change'
				}],
				roomname: [{
					required: true,
					message: '请填写表名',
					trigger: 'blur'
				}],
			},
			metersearchkey: {
				'company':'',
				'officename': '',
				'roomname': '',
				'id': '',
				'mac': ''
			},
			monthconfig: {
				month: '',
				price: '',
				allmoney: '',
				allwat: ''
			},
			monthconfig_rule: {
				
			},
			daoru:{
				day:'',
				tp:''
			},
			daoru_rule:{
				day: [{
					required: true,
					message: '选择抄表日期',
					trigger: 'blur'
				}],
				tp: [{
					required: true,
					message: '选择表格类型',
					trigger: 'blur'
				}],
			},

			invoice:{
				day:'',
				tp:''
			},
			invoice_rule:{
				invoice_in_time: [{
					required: true,
					message: '选择发票日期',
					trigger: 'blur'
				}]
			},

			showaddconfig: false,
			belong: [],
			pgno: 1,
			pgcount: 0,
			pgsize: 13,

			month_pgno: 1,
			month_pgcount: 0,
			month_pgsize: 13,

			elemeter_pgno: 1,
			elemeter_pgcount: 0,
			elemeter_pgsize: 13,
			searchproperty: '空',
			chproperty: {},
			searchkey: {
				keywords: '',
				number: '',
				prototype: {
					'buildch': [],
					'floorch': [],
					'roomch': []
				},
				month:'',
				tp:'',
				use: ''
			},
			building: [],
			filter: {},
			meters: [],
			elemeter: [],

			down: 0,
			config: [],
			multipleSelection: [],
			reading:false,
			percent:0,
			meterlog:[],
			meterType:[],
			meterSecondType:[],
			secondType:[],
			acmeter:'',
			meterchangelog:[],
			meterChangeLog_pgno:1,
			meterChangeLog_pgcount:1,
			downbill:0,
			isshowload:false,
			fileList:[],
		}
	},
	mounted: function () {

		this.bid = this.$route.query.bid ? this.$route.query.bid : ''
		this.host = this.$host
		this.uploaddata.pusern=localStorage.getItem('pusern');

		this.ini();
		this.getdata();
	},
	methods: {
		uploadfloorpicsuccess(response) {
			this.uploadForm.files.push(response)
			if(this.uploadForm.files.length==this.fileList.length){
				this.upefile=false;
				this.uploadsubmitForm();
			}
			
		},
		filechange(file,fs){
			this.fileList=fs;
		},
		uploadsubmitForm(v){
			this.$refs['daoru'].validate((valid) => {
				if (valid) {
					this.upelestate=true;
					this.daoru.do='load';
					this.daoru.fileid=this.uploadForm.files[0].fileid;
					this.$ajax({
						url: 'meter/',
						data: this.daoru
					}).then(res => {
						this.upelestate=false;
						this.uploadForm.files=[];
						this.$refs.uploadfloorpic1.clearFiles();
						this.$alert(res.msg, '提示', {
							confirmButtonText: '确定'
						});
						
						
					})
				}
			})
		},
		canceldaoruform(){
			this.isshowload=false;

		},
		subdaoru(){
			if(this.fileList.length==0){
				this.$alert('请选择上传的文件');
				return;
			}
			this.$refs['daoru'].validate((valid) => {
				if (valid) {
					this.upefile=true;
					this.$refs.uploadfloorpic1.submit();
				}
			})
		},

		showloadfile(){
			this.isshowload=true;
		},
		showviewBill(){
			if(this.searchbill.month==''){
				
				this.$alert('选择要生成账单的月份')
				return;
			}
			this.isshowinvoice=true;
		},
		subinvoice(){
			this.$refs['invoice'].validate((valid) => {
				if (valid) {
					this.viewBill('save')
				}
			})
		},
		viewBill(dos){
			if(dos=='down'){
				this.downbill=1;
			}else{
				this.downbill=0;
			}

			if(this.searchbill.company&&this.searchbill.contractid==''){
				this.$ajax({
					url: 'meter/',
					data: {
						'do': 'contract',
						'company': this.searchbill.company
					},
				}).then(res => {
					if(res.error>0){
						this.chcompany=true;
						this.backfun='bill'
						this.companyList=res.data;
					}else{
						this.searchbill.contractid=res.data
						this.viewBill(dos);
					}
					
				})
			}else{
				if(this.searchbill.company==''){
					this.searchbill.contractid='';
				}
				this.$ajax({
					url: 'meter/',
					data: {
						'do': 'createBill',
						'search':this.searchbill,
						'down': this.downbill,
						'save':dos=='save'?1:0,
						'invoice_in_time':this.invoice.invoice_in_time
					}
				}).then(res => {
					if (this.downbill) {
						this.downbill = 0;
						let url = window.URL.createObjectURL(new Blob([res]));
						let link = document.createElement('a')
						link.download = '水电费账单.xls'
						link.href = url
						link.click()
						return
					}
					if(res.error>0){
						this.$alert(res.msg)
					}else{
						if(dos=='save'){
							this.$alert('账单已生成')

						}
						res=res.data;
						this.bill=res.billLog;
					}
				});
			}
		},
		tpchange(){
			this.secondType= this.meterSecondType[this.meterform.tp-1];
			this.meterform.stype='';
		},
		surecompany(id){
			this.chcompany=false;
			this.companyList=[];
			if(this.backfun=='bill'){
				this.searchbill.contractid=id;
				this.viewBill();
			}else{
				this.metersearchkey.contractid=id;
				this.getmeter();
			}
			
			
		},
		logchange(v,e){
			if(this.activeName=='second'){
				this.showMeterChangeLog();
			}
		},
		showlog(row){
			if(isNaN(row)){
				this.acmeter=row;
			}
			this.activeName='first'
			this.$ajax({
				url: 'meter/',
				data: {
					'do': 'meterWriteLog',
					'meter_id':this.acmeter.id,
					'pgno':this.meterLog_pgno,
					'pgsize':9
				}
			}).then(res => {
				if(res.error>0){
					this.$alert(res.msg)
				}else{
					res=res.data;
					this.meterlog=res.res;
					this.showMeterLog=true;
					this.meterLog_pgcount=res.count;
					this.event_name=res.event_name;
				}
			});
		},
		showMeterChangeLog(){
			this.$ajax({
				url: 'meter/',
				data: {
					'do': 'meterChangeLog',
					'meter_id':this.acmeter.id,
					'pgno':this.meterChangeLog_pgno,
					'pgsize':9
				}
			}).then(res => {
				if(res.error>0){
					this.$alert(res.msg)
				}else{
					res=res.data;
					this.meterchangelog=res.res;
					this.meterChangeLog_pgcount=res.count;
				}
			});
		},
		handleSelect(item) {
			this.meterform.contractid=item.id;
			this.comkey=item.company
		},
		clearcontract(){
			this.comkey='';
			this.meterform.contractid=0;
		},
		searchcom(queryString,cb){

			if(queryString!=''){
				this.$ajax({
					url: 'contract/',
					data: {
						'do': 'search',
						'key':queryString
					}
				}).then(res => {
					if(res.res==''){
						cb([])
					}else{
						cb(res.res);
					}
				});
			}

		},
		stepread(id){
			this.$ajax({
				url: 'meter/',
				data: {
					do: 'addmeterlog',
					po: [this.readmeter[id].id]
				}
			}).then(res => {
				this.readmeter[id].readed=1;
				for(var i=0;i<this.readmeter.length;i++){
					if(this.readmeter[i].readed!=1){
						this.stepread(i);
						break;
					}
				}
				if(i==this.readmeter.length){
					//this.reading=false;
					for(i=0;i<this.readmeter.length;i++){
						this.readmeter[i].readed=0;
					}
					this.readmeter=[];
					this.percent=100
					this.reading=false;
				}else{
					this.percent=parseInt(i/this.readmeter.length*100)
				}

			})
		},
		read() {
			if(this.reading){
				this.$alert('正在读取', '提示', {
						confirmButtonText: '确定'
					});
					return;
			}
			var po = [];
			if (this.multipleSelection.length == 0) {
				this.$confirm('确认要对所有表进行抄表吗？', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$ajax({
						url: 'meter/',
						data: {
							do: 'meter',
							all:1
						}
					}).then(res => {
						this.reading=true;
						this.percent=0;
						this.readmeter=res.data.res
						this.stepread(0)
					})
				}).catch(() => {

				});
				
			}else{
				for (var i = 0; i < this.multipleSelection.length; i++) {
					po.push(this.multipleSelection[i]);
				}
				this.percent=0;
				this.reading=true;
				this.readmeter=po;
				this.stepread(0)
			}
			


		},
		handleSelectionChange(val) {
			this.multipleSelection = val;
		},
		clearofficeid() {
			this.meterform.officeid = '';
			this.linkpropertyname = '';
		},
		chs() {
			if (this.linkproperty.roomch.length > 0) {
				this.meterform.officeid = this.linkproperty.roomch[0]

				this.linkpropertyname = this.linkproperty.propertyname
			}
		},
		closemeterform() {
			this.isshowaddmeter = false;
		},
		modimeter(v) {
			this.isshowaddmeter = true;

			this.meterform = {
				'tp':parseInt(v.tp),
				'stype':parseInt(v.stype),
				'belong':parseInt(v.belong),
				'officeid': v.officeid,
				'roomname': v.roomname,
				'contractid': v.contractid,
				'number': v.number,
				'magnification': v.magnification,
				'maddr': v.maddr,
				'mac': v.mac,
				'note': v.note,
				'virtual':v.virtual
			};

			this.acmeter=v.id;
			this.comkey=v.company
			this.linkpropertyname = v.bname + v.roomnumber;
		},
		submeter() {

			this.$refs['meterform'].validate((valid) => {
				if (valid) {
					this.$ajax({
						url: 'meter/',
						data: {
							'do': this.acmeter?'editmeter':'addmeter',
							'id':this.acmeter,
							'formdata': this.meterform
						},
					}).then(res => {
						if(res.error>0){
							this.$alert(res.msg);
						}else{
							this.acmeter=0;
							this.getmeter()
							this.isshowaddmeter = false;
						}
						
					})
				}
			})
		},
		delmeter(v) {
			this.$ajax({
				url: 'meter/',
				data: {
					'do': 'delmeter',
					'id': v.id
				},
			}).then(res => {
				if(res.error>0){
					this.$alert(res.msg)
				}else{
					this.getmeter()
				}
			})
		},
		showaddmeter() {
			this.isshowaddmeter = true;
			
			this.acmeter='';
			this.meterform = {
				'tp':1,
				'stype':'',
				'officeid': '',
				'contractid':'',
				'roomname': '',
				'number': '',
				'magnification': '',
				'maddr': '',
				'mac': '',
				'note': '',
				'virtual':'1'
			}
			this.comkey='';
		},
		newconfig() {
			this.showaddconfig = true
			this.monthconfig = {
				month: '',
				price: '',
				allmoney: '',
				allwat: ''
			}
		},
		delconfig(v) {
			this.$ajax({
				url: 'meter/',
				data: {
					'do': 'delconfig',
					'id': v.id
				},
			}).then(res => {
				this.getconfig()
			})
		},
		cancelconfigform() {
			this.showaddconfig = false;
		},
		subconfig() {
			this.$ajax({
				url: 'meter/',
				data: {
					'do': 'config',
					'op': 'set',
					'formdata': this.monthconfig
				},
			}).then(res => {
				if (res.error > 0) {
					this.$alert(res.msg, '提示', {
						confirmButtonText: '确定'
					});
				} else {
					this.getconfig()
				}

			})
		},
		chac(v) {
			this.active = v;
			if (v == 'meterlist') {
				this.getdata();
			} else if (v == 'elemeter') {
				this.getmeter();
			} else if (v == 'config') {
				this.getconfig();
			} else if (v == 'lun') {
				this.adform.type = 'apa_lun';
				this.ads_title = '公寓轮播图设置'
				this.getads()
			} else if (v == 'business') {
				this.adform.type = 'apa_shop';
				this.ads_title = '商业服务'
				this.getads();
			}
		},
		modiconfig(obj) {
			this.monthconfig = obj;
			this.showaddconfig = true;
		},
		getconfig() {
			this.$ajax({
				url: 'meter/',
				data: {
					'do': 'config',
					'op': 'list',
					'pgno': this.month_pgno,
					'pgsize': this.month_pgsize,
				},
			}).then(res => {
				this.config = res.data.res;
				this.month_pgcount = res.data.count;
			})
		},
		ch() {
			this.searchproperty = this.chproperty.propertyname;
			this.searchkey.prototype = {
				'buildch': this.chproperty.buildch,
				'floorch': this.chproperty.floorch,
				'roomch': this.chproperty.roomch
			};
			this.getdata();
		},
		edit() {

		},
		ini() {

		},
		getmeter() {
			if(this.metersearchkey.company&&this.metersearchkey.contractid==''){
				this.$ajax({
					url: 'meter/',
					data: {
						'do': 'contract',
						'company': this.metersearchkey.company
					},
				}).then(res => {
					if(res.error>0){
						this.chcompany=true;
						this.companyList=res.data;
					}else{
						this.metersearchkey.contractid=res.data
						this.getmeter();
					}
					
				})
			}else{
				if(this.metersearchkey.company==''){
					this.metersearchkey.contractid='';
				}
				this.$ajax({
					url: 'meter/',
					data: {
						'do': 'meter',
						'search': this.metersearchkey,
						'pgno': this.elemeter_pgno,
						'pgsize': this.elemeter_pgsize,
					},
				}).then(res => {
					this.elemeter = res.data.res;
					this.elemeter_pgcount = res.data.count;
				})
			}
			
		},
		getdata(v) {
			if (v == 'down') {
				this.down = 1;
			} else {
				this.down = 0;
			}
			this.$ajax({
				responseType: this.down ? 'blob' : '',
				url: 'meter/',
				data: {
					'do': 'meters',
					'searchkey': this.searchkey,
					'down': this.down,
					'pgno': this.pgno,
					'pgsize': this.pgsize
				},
			}).then(res => {
				if (this.down) {
					this.down = 0;
					let url = window.URL.createObjectURL(new Blob([res]));
					let link = document.createElement('a')
					link.download = '电表统计.xls'
					link.href = url
					link.click()
					return
				}
				this.belong=res.data.belong;
				this.meterType=res.data.meterType;
				this.meterSecondType=res.data.meterSecondType;
				this.meters = res.data.res;
				this.pgcount = res.data.count;
			})
		}
	}
}

</script>
